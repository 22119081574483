// AAW Farben

// ROT
$magenta: rgb(214,0,110);
// GRÜN
$maigruen: rgb(94,224,69);

// SCHWARZ
$schwarz: #222;

// WEISS
$weiss: #fff;

//GRAU
$hellgrau: rgb(232,232,232);
$dunkelgrau: #444;

// GELB
$gelb: rgb(252,189,48);
$sandgelb: rgb(253,222,151);

// BLAU
$hellblau: rgb(153,204,255);

// TRANSPARENT 
$shadow-sidebar: rgba(000,000,000,0.5);
$milchglas: rgba(255,255,255,0.7);
$grauglas: rgba($hellgrau,0.5);

// TEXT-SHADOW
$default-text-shadow-color: rgba($schwarz, 0.5);
$default-text-shadow-h-offset: 0px;
$default-text-shadow-v-offset: 0px;
$default-text-shadow-blur: 20px;
$default-text-shadow-spread: false;

// BODY DEFAULT
$default-bg-color: $hellgrau;
$default-text-color: $schwarz;

// LINK DEFAULT
$default-a-link-color: $magenta;
$default-v-link-color: $magenta;
$default-h-link-color: $magenta;

// FOOTER
$footer-bg-color: $dunkelgrau;
$footer-text-color: $weiss;
$footer-a-color: lighten($magenta,25);

// MENU
$menu-bg-color: $magenta;
$menu-a-color: $weiss;
$menu-sub-bg-color: transparent;
$menu-sub-a-color: $magenta;
