// Main font family
@font-face {font-family: "Ebrima";
	src: url("//db.onlinewebfonts.com/t/1ba82d324736a8a9d4327d482c4627c4.eot");
	src: url("//db.onlinewebfonts.com/t/1ba82d324736a8a9d4327d482c4627c4.eot?#iefix") format("embedded-opentype"),
	url("//db.onlinewebfonts.com/t/1ba82d324736a8a9d4327d482c4627c4.woff2") format("woff2"),
	url("//db.onlinewebfonts.com/t/1ba82d324736a8a9d4327d482c4627c4.woff") format("woff"),
	url("//db.onlinewebfonts.com/t/1ba82d324736a8a9d4327d482c4627c4.ttf") format("truetype"),
	url("//db.onlinewebfonts.com/t/1ba82d324736a8a9d4327d482c4627c4.svg#Ebrima") format("svg");
}

// Second font family
@font-face {font-family: "AAWGarde";
	src:  local('AAWGarde'),
	url("../fonts/AAWGarde.woff") format("woff");
}
