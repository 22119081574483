html {
height:100%;
}
#content {
	* a {
		font-size:inherit;
	}
	p, li {
		letter-spacing:0;
		font-family:Ebrima, 'Trebuchet MS', 'Arial Narrow', arial, geneva, helvetica, no-serif;
	}
}
body, h1, h2, h3, h4, h5, h6, p, #footer p, ul, ol, li, dl, a, address, #content address p, img, pre, code, td, th, a img, button {
margin:0;
padding:0;
border:0;
font-size:1em;
line-height:1.4em;
text-decoration:none;
list-style:none;
font-weight:normal;
letter-spacing:0.5px;
font-family:AAWGarde, 'Trebuchet MS', 'Arial Narrow', arial, geneva, helvetica, no-serif;
}


body {
height:101%;
}