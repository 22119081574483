/*

Style für www.gewerbe-welden.de
(c) http://www.webdaum.de 

*/

// AAW FARBEN
@import "color";

// AAW  SCHRIFTEN
@import "fonts";

// COMPASS MIXINS
@import "compass";

// RESET BROWSER
@import "reset";

$header-y: 60px;


body {
	color:$default-text-color;
	background:$default-bg-color url(wolke-grau.png) 250px 250px fixed;
}


td, th {
	vertical-align:top;
	padding:0.5em 2ex 0.5em 0;
	width:auto;
}
th {
	font-weight:bold;
}
h1, h2, h3, h4, h5 {
	font-weight:bold;
	letter-spacing:0.1ex;
}
table,
#content img {
	max-width:100%;
	height:auto !important;
}
iframe {
	max-width:100%;
}
h1, #content h2 {
	text-align:center;
	font-size:16pt;
}
/*

POSITIONING MAIN CONTAINERS

*/
#container {
	position:relative;
	overflow:hidden;
	background:transparent;
}
#menu {
	position:absolute;
	font-size:1.2em;
	z-index:7;
}
/* ASIDE */
aside.recruiting {
	margin-top:20px;
	background:$maigruen url(ballon.png) 50% 25px no-repeat;
	
	p {
		font-size:20pt;
		text-shadow:0px 0px 40px #000;
	
		a {
			padding: 140px  40px 40px 40px;
			color:$weiss;
			display:block;
			height:340px;
			box-sizing:border-box;
		}
	}
}
aside.members {
	margin-top:20px;
	margin-bottom:20px;
	background:$magenta url(wolke.png) 50% 25px no-repeat;
	
	p {
		color:$weiss;	
		font-size:12pt;
		
		a {
			box-sizing:border-box;
			padding: 110px  40px 40px 40px;
			color:$weiss;
			display:block;
			height:340px;
		}
		strong {
			text-shadow:0px 0px 40px #000;
		}
	}
}
/*

SEARCH

*/

#sidebar-3 {

	background:$weiss;
	margin-bottom:20px;
	
	div.search {
		max-width:300px;
		margin-top:2em;
		margin:0 auto;
		 a {
			line-height:1.2em;
		}
		h2{
			text-shadow: 0px 0px 40px #000;
		}
	}
}
form.search {
		max-width:500px;
		position:relative;
	div.tags {
		position:absolute;
		top:50px;
	}
	div.ac_results {
		color:$schwarz;
	}
	input.search-words,
	input.search-submit {
		height:40px;
		display:inline-block;
		padding:2px;
		box-sizing:border-box;
		border:1px solid $shadow-sidebar;
	}
	input.search-words {
		width:85%;
		max-width:440px;
		margin-right:5px;
		float:left;
	}
	input.search-submit {
		float:right;
		margin:0;
	}
}
a#search-button,
.search-submit {
		width:40px;
		height:40px;
		background:$magenta url(search-icon.png) 50% 50% no-repeat;
		color:transparent;
	}
ul.search-results {
	clear:both;
}
#logo2 {
	position:absolute;
	top:-400px;
	transition:1s;
}
ul.search-results,
#footer ul {
	overflow:hidden;
}
a#search-button {
	display:block;
	right:50px;
	position:fixed;
	top:($header-y - 40px)/2;
	font-size:1px;
	background:$schwarz url(search-icon.png) 50% 50% no-repeat;
	color:transparent !important;
	border-radius:5px;
	z-index:5;
}
body#suche {
	background:$magenta;
	
	#header .contact,
	aside,
	#menu #search-button,
	#footer {
		display:none;
	}
	.wrap {
		background:transparent;
	}
}
#content .search-entry {
	padding:1em 0;
	transition:1s;
	box-sizing:border-box;
	margin: 0;
}

.search-entry {
	background-color:rgba($weiss,0.3);
}
.search-entry:active,
.search-entry:hover,
.search-entry:focus {
	background-color:rgba($weiss,1);
}

.search-results {
	li + li {
		border-top:1px solid $shadow-sidebar;
	}
}

li.search-entry a.detail {
	display:block;
	clear:right;
	text-align:right;
}

li.search-entry .beschreibung:after {
	content:' [...]';
}
#suche div.tags span,
h2.search-header {
	display:none;
}
/*

FOOTER

*/
#footer {
	background:$footer-bg-color;
	color:$footer-text-color;
	padding:2em 2ex 1em 2ex;
	margin:0;
	width:100%;
	bottom:0;
	clear:both;
	position:relative;
	z-index:3;
	text-align:center;
	
	 p {
		font-size:0.9em;
		line-height:1.4em;
	}
	span.minor {
		display:none;
	}
}
/*

FORMULAR 

*/
form {
	max-width:360px;
}
form input[type="text"],
textarea {
	display:block;
	width:100%;
	margin-bottom:1em
}

/*

FARBE

*/
a:link,* a:visited {
	color:$default-a-link-color;
}
#content a.mehr, .nm_readmore {
	line-height:1em;
	margin-top:1em;
	display:block;
	padding-left:15px;
}
#footer a {
	color:$footer-a-color;
}
/*

MENU

*/

ul#menu-list {

	box-shadow:0  20px 20px $shadow-sidebar;
	position:fixed;
	display:none;
	right:0;
	left:10%;
	width:90%;
	height:100%;
	top:$header-y;
	padding-top:1em;
	background-color:$weiss;
	font-size:1.1em;
	min-height:100%;
	
	ul {
			margin-left:30px;
			/*
			$line-height: 35px;
			font-size:($line-height - 10);
			line-height:$line-height;
			max-height:(($line-height+1)*8-1);
			*/
			overflow-y:auto;
	}
	
	li {
		padding:1em 2ex;
		line-height:75px;
		box-sizing:border-box;
		a {
			display:block;
			color:$dunkelgrau;
		}
	}
	
	li + li {
		border-top:1px solid $hellgrau;
	}
	
	li.current, li.currentpath {
		background-color:$magenta;
		a {
			color:$weiss;
			font-weight:bold;
		}
	}
	
	li.open {
		background:$menu-sub-bg-color url(is-closed.png) 90% 1em no-repeat;
		max-height:75px;
		overflow:hidden;
		li {
			margin-top:1em;
		}
	}
	
	li.open.current, li.open.currentpath {
		background:$magenta url(is-open.png) 90% 1em no-repeat;
		max-height:400px;
		
		li a {
			font-weight:normal;
		}
		li.current {
			background-color:$menu-sub-bg-color;
			a {
				font-weight:bold;
			}
		}
	}
}
#menu-button {
	display:block;
	background:$schwarz url(open-menu.png) 50% 50% no-repeat;
	right:5px;
	width:40px;
	height:40px;
	position:fixed;
	top:($header-y - 40px)/2;
	font-size:1px;
	color:transparent !important;
	z-index:5;
	border-radius:5px;
}
#menu .menu,
#menu.hide{
	display:none;
}
#menu.show {
	display:block;
}
/*

HEADER

*/

#header {
	top:0;
	left:0;
	position:absolute;
	z-index:6;
	width:100%;
	transition:1s;
	
	div.contact {
		margin-top:$header-y;
		background:$milchglas;
		overflow:hidden;
		
		p {
			box-sizing:border-box;
			font-size:0.8em;
			width:40%;
			padding:0.5em 2ex;
			float:left;
			line-height:1.1em;
		}
		p + p {
			width:55%;
		}
	}
	
	h2 {
		top:0;
		right:0;
		left:0;
		height:$header-y;
		background-color:$magenta;
		padding-right:40px;
		margin:0;
		position:fixed;
		color:$weiss;
		
		a {
			color:$weiss;
			line-height:$header-y;
			display:block;
			height:$header-y;
			font-weight:bold;
			text-align:center;
			background:url(logo-mobile.png) 0 50% no-repeat;
			transition:1s;
			margin-left:5%;
			margin-right:5%;
			
			span {
				display:none;
			}
		}
	}
}
/* BETRIEBE */

.betrieb {
	overflow:auto;
	address {
		font-style:normal;
	}
	.outer-logo {
		width:180px;
		height:180px;
		display:block;
		overflow:hidden;
		position:relative;
		margin:0 auto;
		z-index:0;
		
		img {
			transition:1s;
			position:absolute;
			top:-180px;
			left:0;
		}	
	}
	.teaser:active,
	.teaser:hover,
	.teaser:focus {
		.outer-logo img {
			top:0;
		}
	}
	a.anfahrt {
		display:block;
		background:url(anfahrt.png) 100% 50% no-repeat;
		color:$default-text-color;
		margin-right:10px;
		padding-right:10px;
	}
}
#betriebe, #index  {
	.outer-logo img {
		top:0;
	}
	.teaser:active,
	.teaser:hover,
	.teaser:focus {
		.outer-logo img {
			top:-180px;
		}
	} 
}
/*

CONTENT

*/
#content {
	
	margin-top:140px + $header-y;
	
	ul, ol {
		list-style-position:inside;
	}
	ol + ol, h2 + ol, h3 + ol,
	ul + ul, h2 + ul, h3 + ul,
	p + p, h2  + p, h3  + p,
	li, p + ul,
	li  ul > :first-child,
	li  ol > :first-child {
		margin:0.5em 0;
	}
	ul > :first-child,
	ol > :first-child {
		margin-top:0
	}
	p + h3, dl + h3, ul + h3, table  + h2 , table  + h3,p + h2, dl + h2, ul + h2 {
		margin-top:4em;
		clear:both;
	}

	.wrap {
		max-width:630px;
		padding:0 2ex;
		background:$grauglas;
		margin:0 auto;
	}
	div.iframe,
	.holder {
		padding:1em;
		background:$weiss;
		margin:0 auto;
		box-sizing:border-box;
	}
	div.iframe {
		max-width:630px;
	}
	h1 {
		text-align:center;
		color:$weiss;
		text-shadow: 0px 0px 40px #000;
		font-weight:bold;
		padding-bottom:4em;
	}
	h2, h3 {
		text-align:center;
		color:$magenta;
	}
	h2.tagline {
		font-size:2em;
	}
	#adds *, *, p {
		text-align:center;
	}
	a:active, a:hover, a:focus {
		text-decoration: underline;
	}
	h3 + p, h4 + p {
		margin-top: 1.2em;
	}
	dt {
		  clear: left;
		  float: left;
		  font-weight: 700;
		  margin-right: 1ex;
		  display: block;
	}
	dd {
		  display: block;
		  margin: 0px 0 1em 5em;
		  min-height: 1.3em;
		  min-width: 1em;
		  width: auto;
	}
	p.aktualisiert-am:before {
		content: 'Zuletzt aktualisiert am ' ;
	}
	h2[id],
	h3 {
		padding-top:$header-y;
		clear:left;
	}
	.betriebe {
		h3[id] {
			background:#ccc;
		}
		h3[id], .nav {
			a, span {
				display:block;
				text-align:center;
				width:180px;
				height:180px;
				line-height:180px;
				color:$weiss;
			}
			a {
				background:#ccc;
				margin:0 auto 45px auto;
				transition:1s;
			}
			span {
				background:$magenta;
			}
		}
		
		.nav a:active,
		.nav a:hover,
		.nav a:focus,
		h3[id]:hover{
			background:$magenta;
		}
	}
}
.pp_nav,
.pp_arrow_next,
.pp_description {
	color:$schwarz;
}
.left {
	float:left;
	padding-top:0.5em;
	margin-right:3ex;
	margin-bottom:1em;
}
.right {
	float:right;
	padding-top:0.5em;
	margin-left:3ex;
	margin-bottom:1em;
}
.clear {
	clear:both;
}
a.skip-to-top {
	position:fixed;
	bottom:60px;
	right:10px;
	font-size:2em;
	font-weight:bold;
	text-shadow:0 0 10px $shadow-sidebar;
}
/* 

INDEX

*/
#index {

	#slides {
	background:url(00.jpg) 50% $header-y fixed no-repeat;
	background-size:100% auto;
	-moz-background-size:100% auto; 
	transition:1s;
	position:fixed;
	//z-index:2;
	width:100%;
	height:100%;
	/* TRY TO FIX CHROME ISSUES on mobiles by 3D hardware exceleration */
	-webkit-transform:translateZ(0);
	}
	
	h1 {
		font-size:42pt;
		line-height:0.8em;
		position:absolute;
		top:300px;
		width:100%;
		transition:1s;
		left:0;
	}
	
	#content {
	
		margin-top:360px + $header-y;
		
		.wrap {
			max-width:100%;
			margin:0;
		}
		.holder h2, h3 {
			clear:left;
			padding-top:3em;
			padding-bottom:2em;
		}
		h2, aside h2 {
			color:$magenta;
		}
		h1 + h3 {
			clear:none;
			margin-top:0;
			padding-top:0;
		}
		p.aktualisiert-am {
			color:$shadow-sidebar;
			font-size:0.8em;
			text-align:center;
		}
	}
}

#skip2events {
	margin:-10px;
	padding:5px;
	a {
		display:inline-block;
		float:left;
		width:150px;
		height:150px;
		margin:5px;
		position:relative;
		
		strong {
			line-height:50px;
			display:inline-block;
			float:left;
			width:150px;
			text-align:center;
			position:absolute;
			bottom:0px;
			left:0px;
			transition:1s;
		}
	}
	a:active strong,
	a:hover strong,
	a:focus strong {
		line-height:100px;
	}
}


/* ADAPTIVE POSITIONING START */

@media all and (min-width: 414px) {
	body {
		background-position:50% 0;
	}
	
	#index {
		h1 {
			top:350px;
		}
		#content {
			margin-top:414px + $header-y;
		}
	}
	aside.recruiting, aside.members {
		max-width:48.5%;
		float:left;
	}
	aside.recruiting {
		margin-right:3%;
	}
}

@media all and (min-width: 768px) {
	
	#header.mini h2 {
		height: 80px;
		a {
			height: $header-y;
			background: url(logo-mobile.png)  0 50% no-repeat;
		}
	}
	
	
	#menu.mini {
		background:transparent;
		opacity:1;
		background:transparent;
		height:$header-y;
		
		ul#menu-list {
			padding-top:0;
			margin-top:25px;
			height:$header-y;
			
			a {
				font-size:0.9em;
			}
				
			ul {
			padding-top:25px;
			
				a {
					font-size:0.8em;
					font-weight:normal;
				}
			}
		}
		
	}
	

	// SET NEW HEADER HEIGHT:
	$header-y:140px;
	
	#header {
		h2 {
			transition:1s;
			height: $header-y;
			
			a {
				height: $header-y;
				width:180px;
				background:url(logo-mittel.png) 0 50% no-repeat;
			}
		}
		div.contact {
			margin-top:$header-y;
			.wrap {
				width:60%;
			}
		}
	}
	
	* #menu:active, * #menu:hover, * #menu:focus {
		opacity:1;
	}
	#menu {
		position:fixed;
		width:100%;
		top:0;
		left:0;
		transition:1s;
		margin-left:230px;
		
		ul#menu-list {
			transition:1s;
			box-shadow:none;
			top:0;
			width:100%;
			display:block !important;
			background:transparent;
			font-size:1em;
			position:static;
			
			li { 
				border-right:1px solid $menu-a-color;
				display:inline-block;
				float:left;
				padding:0px 1ex;
				white-space:nowrap;
			}
			
			li.open {
				padding-right:30px;
				background:url(small-menu.png) 95% 50% no-repeat;
			}
			li + li {
				border-top:none;
			}
			li:last-child {
				border-right:none;
			}
			li.currentpath, li.current {
				//background:transparent url(is-open-big.png) 50% 20px no-repeat;
				a {
					font-weight:bold;
				}
				ul {
					z-index:7;		
					li a {
						font-weight:normal;
					}
				}
			}
			a {
				font-size:1.1em;
				color:$menu-a-color;
			}
			li ul {
				position:absolute;
				margin-left:0;
				left:-230px;
				padding:0;
				padding-top:175px;
				width:100%;
				display:none;
				//overflow-y:visible;
				min-height:300px;
				max-height:400px;
				z-index:9;
				background:url(white.png) 0 175px no-repeat;
				background-size:(3000px, 400px);
				transition:1s;
				height:auto;
				box-shadow:10px  20px 20px $shadow-sidebar;
				
				li {
					background:$weiss;
					border:0;
					display:block;
					float:none;
					margin:0;
					padding:0;
					a {
						padding-left:30%;
						display:block;
						color:$dunkelgrau; //!important;
						font-weight:normal;
						font-size:0.9em;
						line-height:2em;
					}
				}
				li.first {
					padding-top:2em;
				}
				li.last {
					padding-bottom:2em;
				}
			}
			li:hover ul, li:active ul, li:focus {
				margin-top:0;
				opacity:1;
				z-index:10;
				display:block;
			}
			/*
			li.open {
				background:$menu-sub-bg-color url(is-closed.png) 90% 1em no-repeat;
			}
			*/
			li.current, li.currentpath {
				// background-image:none;
				ul {
					//background:$menu-sub-bg-color url(is-closed.png) 50% 50px no-repeat;
					
					li.current a {
						color:$magenta;
						font-weight:bold;
						background:$weiss;
					}
				}
				
			}
		}
	}
	#menu.mini ul#menu-list ul {
		padding-top:25px;
		min-height:0;
	}
	#header h2 a span,
	a#search-button,
	a#menu-button {
		display:none;
	}
	
	#content {
		padding-top: $header-y * 2;
		padding-bottom:50px;
		margin-top:0;
		
		*, p {
			text-align:left;
		}
		h2[id],
		h3[id] {
			padding-top:$header-y;
			clear:left;
		}
		.betriebe {
			.nav {

				a {
					float:left;
					margin:0 45px 45px 0;
				}
			}

		}
	}
	
	#index {
		#slides {
			background-size:cover;
			background-position: 50% ($header-y /2);
		}
		
		h1 {
			font-size:48pt;
			top:700px;
		}
		#content {
			margin-top:600px;
			.holder {
			max-width:630px;
			}
		}
	}
	
	#footer {
		position:relative;
		height:auto;
		padding:60px 0 80px 0;
		
		li[class] {
			width:33.333%;
			margin:0;
			//padding:2em 20%;
			display:inline-block;
			float:left;
			box-sizing:border-box;
			background:$maigruen;
			min-height:200px;
			
			h2 {
				margin-bottom:1em;
				font-size:16pt;
				font-weight:normal;
			}
		}
		li.partner,
		li.kontakt {
			border-right:1px solid $weiss;
		}
	}
	#sidebar-3 {
		position:fixed;
		transition:1s;
		right:30px;
		top:($header-y + 20px);
		background:transparent;
		z-index:8;
		width:40%;
		
		h2, form div.tags a {
			display:none;
		}
	}
	#sidebar-3.mini {
		right:$header-y * -3;
	}
	
	section#adds {
		width:630px;
		margin:0 auto;
	}
	aside.recruiting, aside.members {
		max-width:48.5%;
		float:left;
		width:305px;
		max-width:305px;
		p a {
			height:305px;
		}
	}
	.betrieb {
		margin:0 auto;
		border-left:1px solid $dunkelgrau;
		width: 628px;
		height:182px;
		overflow:hidden;
		
		.outer-logo, address, .deko {
			display:inline-block;
			height:180px;
			float:left;
			border-top:1px solid $dunkelgrau;
			border-bottom:1px solid $dunkelgrau;
		}
		address {
			background:$weiss;
			height:165px;
			padding:15px 0 0 30px;
			width:240px;
			font-size:10pt;
		}
		.deko {
			border-right:1px solid $dunkelgrau;
			position:absolute;
		}
	}
	#betriebe, #welden-scheck {
	
		#content .betriebe {
		
			@for $i from 1 through 12 { 
				$j:($i*3);
				.nav li:nth-child(#{$j}) a {
					margin-right:0;
				}
			}
		}
	}
}

@media all and (min-width: 1024px) {
	
	#header {
	
		div.contact {
			.wrap {
				width:40%;
				margin-left:5%; // wie lLogo!
			}
		}
	}
	
	#sidebar-3 {
		right:50px;
	}
	#sidebar-3.mini {
		right:0;
		top: $header-y / 3;
		z-index:100;
	}
}

@media all and (min-width: 1230px) {

	#index {
		background-size:100%;
	}
	
}
@media all and (min-width: 1366px) {

	// SET NEW HEADER HEIGHT:
	$header-y:160px;
	
	h1 {
	//margin:300px 0 100px 0;
	}

	#header {
		//height:600px;
		h2 {
			a {
				height:200px;
				width:270px;
				background:url(logo-gross.png) 50% 50% no-repeat;
				position:absolute;
			}
		}
		div.contact .wrap {
			margin-left: 180 + 250px;
		}
	}
	
	#header.mini h2 a {
		margin-top:0;
		height:$header-y;
		background:url(logo-mittel.png) 50% 0 no-repeat;
	}
	
	#menu {
		ul#menu-list {
			// margin-top:-45px;
			margin-left:150px;
		}
	}
}

@media all and (min-width: 1600px) {
	
}
/* FIX APPLE BACKGROUND-SIZE */
@media only screen and (device-height: 768px) {

	#index {
		@include background-size(768px 768px); 
		background-position: 0 100%;
	}
}

@media only screen and (device-height: 1024px) {

	#index {
		@include background-size(1024px 1024px); 
	}
}

/* Nur Internet Explorer 10 + 11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

	#header,
	#content,
	nav{
		transition:0;
	}
}